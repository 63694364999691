

#b1:hover{
    color:white;
    background-color: black;
}
#b1{
    color: #164734;
}

#b2:hover{
    color:white;
    background-color: black;
}
#b2{
    color: #164734;
}
#loginSubmit:hover{
    color:white;
    background-color: black;
}
#loginSubmit{
    color: #164734;
}
#regSubmit:hover{
    color:white;
    background-color: black;
}
#regSubmit{
    color: #164734;
}