#applybtn:focus{
    color:white;
    background-color: black;
}
.custom-btn:focus {
    background-color: black !important;
    border: white !important;
  }
  #datepicker{
      display: none;
  }
  #cardTitle:hover{
    color:#007849;
}
