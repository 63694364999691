@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
body,
html,
header,
footer,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
section,
main{
	margin:0px;
	padding:0px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6{
  font-weight: normal
}
.sepration{
  margin-top: 40px;
}
.bg-green-pea{
  background: #154734;
}
h2{
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 700;
}
h4{
  font-size: 22px;
  font-weight: 700;
  
}
.btn{
  border-radius: 25px!important;
  padding: 6px 25px!important;
  text-transform: uppercase;
  height: 40px!important;

}

/*Start: Header*/

header{
  position: fixed;
  top:0px;
  left: 0px;
  width: 100%;
  z-index: 999;
  transition: all .4s;
}
header.normal{
  background: #154734;
}
header .search-section{
  min-width: 164px;
}
.navbar-nav,
.navbar{
  width: 100%;
}
header .navbar-light .navbar-nav .nav-link {
  color:#fff;
  font-size: 15px;
  font-weight: 400;
  padding: 0px;
  margin: 0px 15px;
  border-bottom:2px solid transparent;
  transition: all .4s;
}
header .navbar-light .navbar-nav{
  justify-content: center;
}
header .navbar-light .navbar-nav .nav-link:hover{
  border-bottom:2px solid #fff;
  color:#fff;
  text-decoration: none;
}

header .search-section .navbar-nav{
  justify-content: flex-end;
}
header .search-section .navbar-nav .nav-link:hover{
  border:0px;
  opacity: .5;
}

header .search-section .navbar-nav {
  justify-content: flex-end;
  display: flex;
  flex-direction: inherit;
}
#searchFilter{
  width:280px;
  top:62px;
  position: fixed;
  padding:15px;
  z-index: 999;
  right: 35px;
  background: #fff;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, .3);
  border-radius: 0px;
}
#searchFilter .form-control{
  background: url(/assets/img/searchGreen.svg) no-repeat center right;
  background-position: 95% 12px;
  border-radius: 25px;
}
#SidebarBox{
  position: fixed;
  width: 300px;
  height: 100vh;
  background: #fff;
  right: 0px;
  top: 0px;
  z-index:999;
  box-shadow: -8px 0px 8px rgba(0, 0, 0, .3);
}
#SidebarBox .close{
  position: absolute;
  background: #ffb91d;
  color:#fff;
  right: 15px;
  top:18px;
  font-weight: 400;
  padding: 8px 10px;
  font-size: 14px;
  opacity: 1;
  transition: all .4s;
  border-radius: 25px;
}
#SidebarBox .close:hover{
  background: #154734;
}
#SidebarBox .head{
  padding:15px 15px 12px;
  background: #154734;
}
#SidebarBox .head h4{
  text-transform: uppercase;
  font-size: 22px;
  margin-top: 2px;
}

/*End: Header*/


/*Start: Hero Banner*/
.banner-section{
  height: 615px;
  overflow: hidden;
  position: relative;
}

.banner-section::before{
  position: absolute;
  height: 100%;
  left: 0;
  bottom: 0;
  content: '';
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 61.69%, rgba(78, 103, 94, 0.723958) 82.64%, #154734 100%), linear-gradient(184.15deg, #154734 3.38%, rgba(51, 80, 70, 0.833333) 15.04%, rgba(255, 255, 255, 0) 52.05%);
  width: 100%;

}
.banner-section .caption{
  position: absolute;
  width: 100%;
  z-index: 99;
  height: 100%;
  color:#000;
  display: flex;
  align-items: flex-end;
  text-align: center;
  padding-bottom: 45px;
}
.banner-section .caption h4,
.banner-section .caption h2{
  color:#fff;
}

.banner-section .caption h2{
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 700;
}
.banner-section .caption h4{
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 15px;
}
.banner-section .btn{
  border-radius: 25px;
  padding: 6px 25px;
  text-transform: uppercase;
  height: 40px;

}
.banner-section .btn.btn-warning{
  border:2px solid #fff;
  color:#fff;
  font-size: 16px;
  font-weight: 700;
}
/*End: Hero Banner*/


.undergraduate-section .imgblock,
.undergraduate-section{
  min-height: 388px;
}

.undergraduate-section .infoBlock{
  padding: 40px;
}
.undergraduate-section .infoBlock .btn.btn-outline{
  border:2px solid #ffb91d;
  color:#ffb91d;
  background: none;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.undergraduate-section .infoBlock .btn.btn-outline:hover{
  background: #ffb91d;
  color:#fff;
}
.undergraduate-section .infoBlock li{
  list-style: none;
}
.undergraduate-section .infoBlock li a{
  font-size: 14px;
  font-weight: 300;
  color:#fff;
  text-decoration: underline;
}
.undergraduate-section .infoBlock li a:hover{
  color: #ffb91d;
}

/*Start: Scholarship*/
.bg-green-pea{
	background:#164734;
}
.scholarship-section .scholarship-section-wrap{
	min-height:425px;
}
.scholarship-section .scholarship-section-wrap h2{
	text-transform:uppercase;
	text-align:center;
	padding:30px 0;
  font-weight: bold;
  color:#fff;
}
.scholarship-section .scholarship-section-wrap h2:after {
    content: '';
    display: block;
  	width: 230px;
    height: 2px;
    background: #fff;
    margin: 0px auto;
    margin-top: 10px;
}
.scholarship-section .scholarship-section-img{
  max-width: 1080px;
  margin: 0px auto;
  margin-top:-90px;
  
}
/*End: Scholarship*/



/*Start: About*/
.about-section .about-section-wrap .btn-default{
	color:#ffb91d;
	border:1px solid #ffb91d;
	border-radius:970px;
	font-weight: 500;
	margin-top:20px;
	transition: 1.2s;
}
.about-section .about-section-wrap .btn-default:hover{
	background:#ffb91d;
	color:#fff;
	transition: 1.2s;
}
/*End: About*/


/*Start: Footer*/
footer .main-footer{
	background:#212121;
}
footer .main-footer p{
  color:#fff;
  font-size: 14px;
}
footer .main-footer h5{
	font-size:15px;
	font-weight: 700;
  margin-bottom:30px;
  color: #fff;
}
footer .main-footer a{
	color:#fff;
  transition: .5s;
  font-size: 15px;
}
footer .main-footer a:hover{
	color:#ffb91d;
	text-decoration:none;
	transition: .5s;
}
footer .main-footer li{
  list-style: none;
  font-size: 15px;
}
footer .termCondition-Widget{
  margin-top: 90px;
}
footer .bootom-footer{
	background:#000;
  font-size:12px;
  font-weight: 700;
  color:#fff;
}
footer .bootom-footer a{
	color:#fff;
	transition: .5s;
}
footer .bootom-footer a:hover{
	color:#ffb91d;
	text-decoration:none;
	transition: .5s;
}
footer .bootom-footer a.site-map{
	color:#ffb91d;
	text-decoration:underline;
	transition: .5s;
}
footer .bootom-footer a.site-map:hover{
	text-decoration:none;
	transition: .5s;
}
/*End Footer*/



@media (max-width:1300px){
  header .navbar-light .navbar-nav .nav-link{
    margin: 0px 10px;
  }
 
}
@media (max-width:1200px){
  header{
    background: #154734;
  }
  header .search-section{
    min-width: auto;
    position: absolute;
    top:18px;
    right: 15px;
  }
  .navbar-light .navbar-toggler{
    margin-right: 85px;
    border: 0px;  
  }
  header .navbar-light .navbar-nav .nav-link{
    padding: 6px 0;
  }
  header .search-section .navbar-nav .nav-link{
    padding: 0px 0;
  }
}
@media (max-width:992px){
.scholarship-section .scholarship-section-img {
  margin-top: -30px;
}
}
@media (max-width:767px){
  .banner-section {
    height: 500px;
    overflow: hidden;
    position: relative;
}
.scholarship-section .scholarship-section-wrap{
  padding-bottom: 40px;
}
	.scholarship-section .scholarship-section-img{
		margin-top:0;
	}
	footer .main-footer, footer .bootom-footer, footer .bootom-footer ul{
		text-align:center!important;
	}
	footer .main-footer h5{
		margin:25px 0 10px;
  }
  footer .bootom-footer .list-inline{
    margin-top: 15px;
  }
  footer .termCondition-Widget{
    margin-top: 0px;
  }
}
.navbar-brand {margin-left:15px;}
footer {
  padding: 0!important; 
}