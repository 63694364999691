@import url(https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:700);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
body,
html,
header,
footer,
h1,
h2,
h3,
h4,
h5,
h6,

p,
section,
main{
	margin:0px;
	padding:0px;
	display:block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color:#010101;

}
h2{
	font-weight:700;
	margin-bottom:15px;
}
h3{
	font-size:28px;
	margin-bottom:15px;
}
p{
	line-height:26px;
}
.logo{
  background: url(/logo.png) no-repeat;
  width: 235px;
  height: 100px;
  background-size:100%;
  display: block;
  text-indent: -999999px;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container{
  max-width: 1340px!important;
}

.nav-icon{
  width: 50px;
  height: 30px;
  text-align: right;
  cursor: pointer;
}
.nav-icon .line{
width: 100%;
height: 5px;
background: #fff;
margin-bottom: 10px;
transition: all .4s;
float:right
}
.nav-icon .line.small{
width: 70%;
transition: all .4s;
}
.nav-icon:hover .line{
width: 75%;

}
.nav-icon:hover .line.small{
width: 100%;
}
.topnav {
height: 100%;
width: 0px;
position: fixed;
z-index: 1000;
top: 0;
right: 0;
background-color: rgb(0,0,0);
background-color: rgba(0,0,0, 0.9);
overflow-x: hidden;
transition: 0.5s;
}

.nav-content {
position: relative;
top: 10%;
width: 100%;
text-align: left;
margin-top: 30px;
z-index:1000;
}

.topnav a {
padding: 8px;
text-decoration: none;
font-size: 14px;
color: #fff;
text-transform: uppercase;
display: block;
font-weight: 300;
position: relative;
text-decoration: none;
margin: 0px auto;
max-width: 180px;
overflow: hidden;

}
.topnav a:before{
height: 1px;
background: #FFCE00;
content: '';
width: 100%;
position: absolute;
bottom: 0px;
display: block;
left: 0;
margin-left: -200px;
transition: all .4s;
}
.topnav a:hover:before{
margin-left:0px;
}

.topnav a:hover, .overlay a:focus {
color: #f1f1f1;
text-decoration: none;
}

.topnav .closebtn {
position: absolute;
top: 0px;
right: 20px;
font-size: 45px;
text-decoration: none;
}
.topnav .closebtn:hover{
transform:rotate(-90deg);
top:5px;
right: 25px;
}
.topnav .closebtn:before{
opacity: 0;
}

.login-nav{
  margin-left: 25%;
}
.login-nav a{
  font-weight: bold;
  color:#000;
  opacity: 1;
  display: inline;;
}
.navbar{
  padding: .5em 0!important;
}
.navbar-light .navbar-nav .nav-link{
  color: rgba(255, 255, 255, 1)!important;
}
.navbar-light .navbar-nav .nav-link:hover{
   color:#fff!important;
   text-decoration: underline;
}
a.navbar-toggler{
  background: url(/menu-icon.svg) no-repeat;
  width: 80px;
  height: 40px;
  display: block;
  border: 1px solid #000;
}
header{
  background:transparent;
  padding: 10px 0;
}
.bannerSecton{
  background:#010167;
  margin: 0px 0px;
  padding-bottom: 80px;
  color:#fff;
}
.bannerSecton .row{
  margin: 0px;
  padding: 0px;
}
.bannerSecton .btn.btn-default{
  background: #fff;
  border-radius: 0px;
  padding: 12px 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  border: 0px;
  color:#010167;
  border:3px solid #fff;
}
.bannerSecton .btn.btn-default:hover{
  background: #010167;
  color:#fff;
  border:3px solid #fff;
}
.seprator{
  padding: 80px 0;
}
ul.top-social-widget{
  padding: 0px;
}
ul.top-social-widget li{
  display:block;
  float: left;
  list-style: none;
  margin-right: 20px;
  transition: all .4s;
}
ul.top-social-widget li:hover{
  margin-top: 5px;
}
.bannerSecton .banner-img{
  max-width: 100%;
  height: 700px;
  /*background: url(/Video.png) no-repeat center;*/
  background:url(/scholarships-banner.png) no-repeat scroll 40% 0% / cover;
  background-size: cover;
}
.bannerSecton .col-lg-3,
.bannerSecton .col-lg-7,
.bannerSecton .col-lg-6,
.bannerSecton .col-lg-4
{
  padding:0px;
}

.bannerSecton h2{
  font-size:40px;
  font-family: 'Fira Sans Condensed', sans-serif;  
  color:#fff;
}
.bannerSecton .info-widget{
  padding-right: 30px;
  position: absolute;
  bottom: 0px;
  font-size: 18px;
  max-width: 440px;
}
.how-this-work {
  padding: 140px 0;
}
.how-this-work .banner-img{
  background: url(/howthiswork.png) no-repeat center;
  background-size: cover;
}
.how-this-work h2{
  color:#ffff;
}
.how-this-work{
  background: #007849;
}
.how-this-work .info-widget{
  color:#fff;
  position: relative;
  font-size: 16px;
  padding: 0;
}
.how-this-work  p{
  line-height: 24px;
  margin: 0px;
}
.how-this-work .info-widget .img-widget{
  width: 64px;
  height: 64px;
  float: left;
}
.how-this-work .info-widget .text-wrap{
  margin-left: 100px;
}
.how-this-work .info-widget .text-wrap h4.title{
  font-size: 25px;
  font-weight: 900;
  color:#fff;
}
.how-this-work .grid{
  padding: 0;
  margin-top: 40px;
}

.testimonial-section{
  background: #0375B4;
}
.testimonial-section .info-widget .img-widget{
  width: 75px;
  height: 75px;
}
.testimonial-section .info-widget .img-widget img{
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.testimonial-section .banner-img{
  background: url(/testimonial.png) no-repeat center;
  background-size: cover;
}

.testimonial-section .info-widget .testiInfo{
  background:url(/comma.png) no-repeat;
  padding-left: 20px;
}
.testimonial-section .info-widget .testiInfo name{
  font-weight: 700;
}
.howPay-section{
  background: #FC4A1A;
}

.howPay-section .banner-img{
  background: url(/howpay.png) no-repeat center;
  background-size: cover;
}
.howPay-section .count-widget{
  text-align: center;
   max-width: 180px;
  margin: 0px auto;
  margin-bottom: 40px;
}
.howPay-section .count-widget h3{
  font-size: 55px;
  color:#fff;
  margin-bottom: 40px;
}
.howPay-section .count-widget h3 sub{
  font-size: 18px;
 }
.login-section .form-control{
      border-radius: 0px;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 5px 3px rgba(0, 0, 0, .1);
    height: 45px;
    font-family: 'Fira Sans', sans-serif;

}

.login-section .form-control::-webkit-input-placeholder {
  color: #C4C4C4;
}

.login-section .form-control::-ms-input-placeholder {
  color: #C4C4C4;
}

.login-section .form-control::placeholder {
  color: #C4C4C4;
}
.skip-to-content-link {
  background: #fff;
  height: 30px;
  text-decoration: underline;
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  color:#0000FF;
  cursor: pointer;
}
.skip-to-content-link:focus {
  transform: translateY(0%);
}

.login-section .nav-tabs{
  border-bottom: 0px;
}
.login-section .nav-tabs .nav-link{
  border:0px;
  color:#000;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0px;
  margin-right: 25px;
  font-weight: 700;
  font-size: 18px;
}
.login-section .form-group{
  margin-bottom: 1.2em
}
.login-section .btn{
  border-radius: 0;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.5px;
  padding: 12px 40px;
  border: 0px;
  font-weight: 600;
}
.forgot-password{
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  color:#000;
  float:right;
  margin-top: 14px;
}
.login-section .btn.btn-primary{
  background: #ffc107;
  color: #FFF;
}
.login-section .btn.btn-primary:hover{
  background: #000;
  color:#fff;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  color: #495057;
  font-family: 'Fira Sans', sans-serif;
  
  background-color: #fff;
  border: 0px;
  text-decoration: underline;
}
.login-section .tab-pane{
  padding: 20px 0;
} 
footer{
  background: #262228;
  padding: 150px 0;
  color:#fff;
  font-size: 14px;
}
footer h5{
  font-size:12px;
  color:#fff;
  margin-bottom: 20px;
  text-transform: uppercase;
}
footer ul{
  padding: 0;
}
footer li{
  list-style: none;
  display: block;
  margin-bottom: 12px;
}
footer li a{
  color:#fff;

}
footer li a:hover{
  color:#FFCE00;
  text-decoration: none;
  padding-left: 5px;

}
footer h4{
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 100px;
}
footer .form-control, .btn.btn-outline-secondary{
  border-radius: 0px; 
  border: 0px; 
}
.btn, a{
  transition: all .4s;
}
footer .form-control{
  font-size: 14px;
}
footer .btn.btn-outline-secondary{
  background: #fff url(/submit.svg) no-repeat center;
  padding: 0px 20px;
  transition: all .4s;
 
}
footer .btn.btn-outline-secondary:hover{
  padding: 0px 25px 0px 25px;
}

footer ul.social-widget{
  text-align: center;
  margin-top: 80px;
}
footer ul.social-widget li{
  list-style: none;
  width: 26px;
  height: 26px;
  display: inline-block;
  margin: 0px 10px;
}
footer ul.social-widget li a{
  display: block;
  width: 26px;
  height: 26px;
}
footer ul.social-widget li .icon {
  fill:#fff;
}
.login-section{
  width: 500px;
  padding: 30px 50px;
  margin: 0px auto ;
  background: #fff;
  border-radius: 8px;
  min-height: 250px;
}

.schloarships-section{
  font-family: 'Fira Sans', sans-serif;  
  padding-bottom: 80px;
}
.schloarships-section .schloarship-list{
  padding: 40px;
  box-shadow: -8px 8px 8px rgba(0,0,0,.1);

}
.schloarships-section .schloarship-list .scholarship-card.card{
  border-radius: 0px;
  box-shadow: 4px 4px 4px rgba(0,0,0,.1);
  font-family: 'Fira Sans', sans-serif; 
  margin-bottom: 30px;
  min-height:346px;

}
.schloarships-section .schloarship-list .scholarship-card.card .card-body{
  padding:1.8rem;
}
.schloarships-section .schloarship-list .scholarship-card.card .card-footer{
  padding:1rem 1.8rem;
}
.schloarships-section .schloarship-list .scholarship-card.card .price-value{
  margin:30px 0 20px;
  display: flex;
}
.schloarships-section .schloarship-list .scholarship-card.card .card-text{
  font-size:18px;
  color:#5A6569;
  font-weight: 400;
}
.schloarships-section .schloarship-list .scholarship-card.card .card-title{
  font-size:24px;
  font-family: 'Fira Sans Condensed', sans-serif;  
}
.schloarships-section .schloarship-list .scholarship-card.card .price-value .price{
  border:3px solid #00ADEF; /*#FFCE00;*/
  background: #fff;
  color:#000;
  font-size:18px;
  font-family: 'Fira Sans Condensed', sans-serif;  
  display: inline-block;
  padding:6px 20px;
}
.schloarships-section .schloarship-list .scholarship-card.card .price-value .time{
  font-size:14px;
  color:#5A6569;
  display: inline-block;
  margin-left: 30px;
}
.schloarships-section .schloarship-list .scholarship-card.card .card-footer{
  background: none;
  text-transform: uppercase;
  font-size: 14px;
  border-width: 2px;
}
.schloarships-section .schloarship-list .scholarship-card.card .card-footer a{
  color:#007849;
  margin-top: 8px;
  display: -webkit-inline-box;
}
.schloarships-section .schloarship-list .scholarship-card.card .card-footer a:hover{
  color:#000;
}
.schloarships-section .schloarship-list .scholarship-card.card  .btn{
  border-radius: 0px;
  font-family: 'Fira Sans', sans-serif;  
  background: none;
  font-size:14px;
  text-transform: uppercase;
  padding: 6px 20px;
}
.schloarships-section .schloarship-list .scholarship-card.card  .btn.btn-success{
  border:3px solid #007849;
  color:#007849;
}
.schloarships-section .schloarship-list .scholarship-card.card  .btn.btn-success:hover{
  background: #007849;
  color:#fff;
}
.schloarships-section .filter-sidebar{
  padding: 40px 25px;
}
.schloarships-section .filter-sidebar h5{
  font-size: 18px;
  margin-bottom: 15px;
}
.filter-sidebar .form-group{
  margin-bottom: 2.5rem;
}
.filter-sidebar .form-control {
  border-radius: 0px;
  border: 1px solid #E1E1E1;
  box-shadow: 0px 5px 3px rgba(0, 0, 0, .1);
  height: 45px;
  font-family: 'Fira Sans', sans-serif;
}
.filter-sidebar .custom-control{
  margin-bottom: 10px;
  color:#838383;
}


.filter-sidebar .form-check{
  padding-left: 0px;
  margin-bottom: 10px;
}
.filter-sidebar .value{
  display: block;
  
}
.filter-sidebar .rangeslider-horizontal .rangeslider__fill {
  background-color: #00ADEF;
  box-shadow: none;

}
.filter-sidebar .rangeslider, .filter-sidebar .rangeslider .rangeslider__fill{
  box-shadow: none;
}
.filter-sidebar .rangeslider .rangeslider__handle{
  box-shadow: none;
  width: 22px;
  height: 22px;
  background-color: #00ADEF;
  border: 0px;
  box-shadow: 0px 4px 4px rgba(0,0,0,.2);
}
.filter-sidebar .rangeslider .rangeslider__handle:after{
  display: none;
}
.create-account-section{
  padding: 80px 0 0;
  font-size:24px;
  color:#5A6569;
  font-weight: 700;
}
.create-account-section .btn.btn-default {
  background: #000000;
  border-radius: 0px;
  padding: 12px 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  border: 0px;
  color:#fff;
  border:3px solid #000;
}
.create-account-section .btn.btn-default:hover{
  background: none;
  color:#000;
}

.bannerSecton.main-banner{
  min-height: 850px;
  position: relative;
}
.bannerSecton.main-banner .info-widget{
  position: inherit;
  max-width: 985px;
  margin: 0px auto;
  text-align: center;
  margin-top: 250px;
}
.bannerSecton.main-banner .info-widget .btn{
  border-radius: 25px;
  padding: 10px 45px;
}
.bannerSecton.main-banner .info-widget .btn.btn-default{
  background: #010167;
  color:#fff;
  border-color: #010167;

}
.bannerSecton.main-banner .info-widget .btn.btn-default:hover{
  border-color: #fff;
}
.service-box .box{
  padding:50px 30px;
}
.service-box .box .img-wrap{
  float: left;
  width: 68px;
  height: 68px;
}
.service-box .box .text-wrap{
  margin-left: 80px;
}
.service-box .box .text-wrap h3{
  font-size: 18px;
  font-weight: 700;
  color:#fff;
}
.service-box .box .text-wrap p{
  line-height: 20px;
}
.filler-one{
  background: #010167;
  color:#fff;
}
.filler-two{
  background:rgba(1,1,103,.88);
  color:#fff;
}
.filler-three{
  background:rgba(1,1,103,.82);
  color:#fff;
}
.heading-group{
  max-width: 600px;
  margin: 0px auto;
  padding: 80px 15px 40px 15px;
  text-align: center;
}
.heading-group h2{
  font-size: 36px;
  color:#0A0144;
  text-align: center;
}
.stayupdate-section{
  padding: 40px 0;
  display: block;

}
.stayupdate-section .container{
  max-width: 1000px!important;
}
.graybox{
  background: #f8f8f8;
}
.stayupdate-section .img-block{
  height: 325px;
}
.stayupdate-section .info-block{
  height: 272px;
  position: relative;
  background: #fff;
  box-shadow:4px 4px 4px rgba(0,0,0,.1);
  padding: 50px;  
  top:25px;
  margin: 0px -80px
}
.stayupdate-section .info-block h3{
  font-size: 20px;
  color:#0A0144;
  font-weight: 600;
  margin-bottom: 10px;
}
.stayupdate-section .info-block p{
  line-height: 20px;
  margin-bottom: 25px;
}
.stayupdate-section.howpay .info-block{
  z-index: 99;
  box-shadow:-4px 4px 4px rgba(0,0,0,.1);
}
.education-section .btn,
.stayupdate-section .btn{
  border-radius: 25px;
  padding: 8px 35px;
  text-transform: uppercase;
}
.education-section .btn.btn.btn-default,
.stayupdate-section .btn.btn-default{
  background: #010167;
  color:#fff;
  border-color: #010167;

}
.education-section .btn.btn.btn-default:hover,
.stayupdate-section .btn.btn-default:hover{
  background: #fff;
  color:#010167;
  border-color: #010167;

}
.education-section{
  height: 800px;
  overflow: hidden;
  position: relative;
}
.education-section:before{
  background: rgba(0,0,0,.2);
  display: block;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  content:'';
   position: absolute;
}
.education-section .info{
  color:#fff;
  max-width: 500px;
  margin-top: 300px;
  position: relative;
  z-index: 99;
}
.education-section .info h2{
  color:#fff;
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
}
.education-section .btn{
  padding: 12px 40px;
  font-size: 18px;
  border-radius: 40px;
}
.footer{
  background: #fff;
  color:#c0c0ca;
  padding: 60px 0 0;
}
.footer h3,
.footer h5{
  text-transform: uppercase;
  color:#000;
  font-weight: 700;
} 
.footer li a{
  color:#c0c0ca;
}
.footer li a:hover{
  color:#010167;
}
.footer .input-group{
  border:1px solid #c0c0ca;
  border-radius: 4px;
  padding: 10px;
}
.footer  .footerLast{
  border-top:1px solid #e8e8eb;
  color:#010101;
  margin: 80px 0 0;
  padding-top: 30px; 
  padding-bottom:20px; 
}
.footer  .footerLast ul.social-widget{
  margin-top: 0px;
}
.footer  .footerLast ul.social-widget li .icon {
  fill: #C4C4C4;
}
.header{
  background: none;
}
.header .right-nav .btn{
  border-radius: 25px;
  padding: 6px 20px;
  margin-left: 10px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  
}
.header .right-nav .btn.btn-primary{
  background: #010167;
  border:1px solid #010167;
}
.header .right-nav .btn.btn-primary:hover{
  background: #fff;
  color:#010167;
}
.header .right-nav .btn.btn-info{
  background: #53B2E1;
  border: 1px solid #53B2E1;
}
.header .right-nav .btn.btn-info:hover{
  background: #fff;
  color:#53B2E1;
}
.header .navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1)!important;
  text-transform: uppercase;
  padding: 3px 15px;
  margin-right: 6px;
  border-radius: 25px;
  border:1px solid transparent;
}
.header .navbar-light .navbar-nav .nav-link:hover{
  border:1px solid #fff;
  text-decoration: none;
}
.header .logo{
  width: 200px;
  height: 100px;
  background: url(/logo.png) no-repeat;
  background-size: 100%;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top:-15px;
}
.header .navbar{
  margin-top: 30px;
}
 @media (max-width:992px){
.stayupdate-section .info-block {
  margin: 0px;
  top:0px;
}  
.nav-icon{
  margin-top:10px;
  width: 40px;
  margin-right: 16px;
} 
.nav-icon .line{
  height: 4px;
} 
header{

  padding: 10px 0;
}
.how-this-work {
  padding: 60px 0;
} 
.bannerSecton .info-widget{
  padding: 40px 20px 0;
}
.bannerSecton .banner-img {
  max-width: 100%;
  height: 400px;
}
.bannerSecton h2 {
font-size: 35px;
}
.how-this-work .info-widget .text-wrap h4.title {
font-size: 20px;

}
.bannerSecton .info-widget{
  position: relative;
}
.bannerSecton {
  padding-top: 0px;
}
ul.top-social-widget{
  padding: 20px;
  padding-bottom: 0px;
}
footer{
  padding: 40px 0;
}
footer h4 {
  margin-bottom: 20px;
}
footer h5,
footer p,
footer ul {
  text-align: center;
}
footer h5{
  margin-top: 20px;
}
footer ul.social-widget {
  margin-top: 30px;
}

.footer .footerLast .float-left{
  display: block;
  text-align: center;
  margin-bottom: 20px;
  float:none!important;
  float:initial!important;
}
.service-box .box {
  padding: 30px 20px;
}
.bannerSecton.main-banner{
  min-height: 780px;
}
.bannerSecton.main-banner .info-widget {
  margin-top: 120px;
}
.header .logo {
  width: 150px;
  height: 80px;
  right: 5px;
  left: inherit;
  top:10px;
}
.header{
  padding: 0px;
}
.header .navbar {
 margin-top: 0px;
}
.header .navbar-toggler {
  margin-left: 15px;
  margin-top: 10px;
  color:#fff!important;
}
.header .navbar-collapse{
  background: rgba(255,255,255,.1);
  padding: 20px 10px;
  margin-top: 30px;
}
.header .right-nav{
  margin-top: 10px;
}
header .container{
  padding: 0px;
}
}

/*07 May 2019*/


.stayupdate-section .info-block .content{
  max-height: 80px;
  overflow: hidden;
  margin-bottom: 20px;
}
.videoblock {
  /*margin-top: 40px;*/
}
.videoblock img{
  text-align: center;
  width: 100%;
  box-shadow: 0px 5px 3px rgba(0, 0, 0, .1);
}
.video-react-video{
  width: 100%;
}
.video-react-big-play-button,
.video-react-control-bar {
  display: none;
}
.video-react-controls-enabled{
  padding-top: 0px!important;
}
.video-modal .modal-body{
  padding: 0px;
}
.video-modal .modal-header{
  padding: 0px;
  border: 0px;
}
.video-modal .modal-content{
  background: none;
  border: 0px;
}
.video-modal .close{
  text-shadow: none;
  color:#fff;
  opacity: 1;
  font-size: 30px;
  right:-30px;
}
.video-modal .modal-dialog{
  max-width: 700px;
  border: 0px;
}
.scholarship-card{
  position: relative;
}
.scholarship-card .disable{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.9);
  top:0px;
  left:0px;
  text-align: center;
  padding-top: 120px;
}

stayupdate-section.howpay .container{
max-width: 1240px!important;
}
.videoblock img{
text-align: center;
width: 100%;
box-shadow: 0px 5px 3px rgba(0, 0, 0, .1);
cursor: pointer;
}

.personal-info-section{
  padding: 40px 0;
}
.personal-info-section .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  text-transform: uppercase;
  font-size: 14px;
  color:#fff;
  background: #53B2E1;
  border-radius:0px;
}
.personal-info-section .nav-pills .nav-item a{
  text-transform: uppercase;
  font-size: 14px;
  color:#000;
  border-bottom: 1px solid #d2d3d3;
  margin-bottom:0px;
  border-radius:0px;
}

.pager .page-item.active .page-link{
  background-color: #53B2E1;
  border-color: #53B2E1;
}
.pager  .page-link{
  color:#000;
}
.pager .page-item:last-child .page-link{
  border-top-right-radius: 0rem;
  border-bottom-right-radius:0rem;
}
.pager .page-item:first-child .page-link{
  border-top-left-radius: 0rem;
  border-bottom-left-radius:0rem;
}
.info-form .form-control{
  border-radius: 0px;
}
.info-form .btn.btn-default{
	background: #EE7002;
	border-radius: 0px;
	padding: 6px 20px;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 14px;
	border: 0px;
	color:#fff;
	border:3px solid #EE7002;
}
.info-form .btn.btn-default:hover{
	border:3px solid #000;
	background: #000;
	color:#fff;
}

@media (max-width:767px){
.bannerSecton.main-banner {
min-height: 500px;
}
.videoblock img{
margin-bottom:25px;
}
.stayupdate-section .info-block{
height: auto;
padding: 20px 30px;
}
.stayupdate-section.howpay{
padding: 40px 0px 15px;
}
.education-section {
height:auto;
}
.education-section .info{
margin: 120px 0; 
}
.education-section .btn {
padding: 12px 30px;
font-size: 15px;
}
.education-section .info h2 {
color: #fff;
font-size: 32px;
line-height: 30px;
}
.heading-group h2 {
font-size: 30px;
}
.footer .footerLast .float-right{
float:inherit!important;
}
.footer {
padding: 30px 0 0;
}

.footer .footerLast {
margin: 30px 0 0;
padding-top: 15px;

}
}

#autocomplete input{
display: block;
width: 100%;
height: calc(1.5em + 0.75rem + 2px);
padding: 0.375rem 0.75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
border-radius: 0px;
border: 1px solid #E1E1E1;
box-shadow: 0px 5px 3px rgba(0, 0, 0, .1);
height: 45px;
font-family: 'Fira Sans', sans-serif;
}

#autocomplete input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

input[type="number"] {
    -moz-appearance: textfield;
}

.bannerSecton.main-banner .info-widget {
   margin-top: 190px;
}
.bannerSecton.main-banner .info-widget .btn-info{
  background: #00ADEF;
  border:3px solid #53B2E1;
  padding: 10px 45px;
  text-transform: uppercase;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
}
.bannerSecton.main-banner .info-widget .btn-info:hover{
  border:3px solid #fff;
}

.bannerSecton.main-banner{
  min-height: 606px;
}
.login-section{
  width: 100%;
  max-width: 450px;
}
.card-number{
  background: url(/card.png) no-repeat;
  background-position: 10px 6px;
  padding-left: 60px!important;
}
.form-group.card{
  background-color:#f2f2f2;
  padding: 15px;
}

.personal-info-section .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background: #010167;
}
.personal-info-section form label{
  font-weight: 600;
}
.bannerSecton .banner-img{
  height: 600px;
}
.personal-info-section form{
  background: #fff;
  padding: 20px;
  border: 3px solid rgba(0, 0, 0, .1);
  box-shadow: 5px 5px 3px rgba(0, 0, 0, .1);
}
@media (max-width:992px){
  .bannerSecton.main-banner .info-widget {
    margin-top: 20px;
 }
 .bannerSecton .banner-img{
  height: 400px;
}
}

form label{color: #000000;}
sup{vertical-align: sub; 
    font-size: small; color:#CA483E;}
	
	
	
.popup-section .form-control{
      border-radius: 0px;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 5px 3px rgba(0, 0, 0, .1);
    height: 45px;
    font-family: 'Fira Sans', sans-serif;

}

.popup-section .form-control::-webkit-input-placeholder {
  color: #C4C4C4;
}

.popup-section .form-control::-ms-input-placeholder {
  color: #C4C4C4;
}

.popup-section .form-control::placeholder {
  color: #C4C4C4;
}


.popup-section .nav-tabs{
  border-bottom: 0px;
}
.popup-section .nav-tabs .nav-link{
  border:0px;
  color:#838383;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0px;
  margin-right: 25px;
  font-weight: 700;
  font-size: 18px;
}
.popup-section .form-group{
  margin-bottom: 1.2em
}
.popup-section .btn{
  border-radius: 0;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.5px;
  padding: 12px 40px;
  border: 0px;
  font-weight: 600;
}
.popup-section{
  width: 100%;
  max-width: 450px;
}
.ReactPasswordStrength{border:none !important}
.ReactPasswordStrength .form-control{font-family: 'Fira Sans', sans-serif;font-weight:none; background: transparent;box-sizing: inherit;border: none;box-shadow: none;height: 37px;width:100%;border:1px solid #ced4da;box-shadow:0px 5px 3px rgba(0, 0, 0, .1)}
.ReactPasswordStrength-strength-desc{position: absolute !important;top:28px !important;width:358px !important;left:0px !important;}
.schloarships-section.admin-dashboard{
  padding-bottom: 0px;
}
.schloarships-section.admin-dashboard .schloarship-list{
  box-shadow: none;
}
.sideNav{
  height: 100vh;
  background: #EEF6FB;
  margin-left: -15px;
  position: relative;
  max-width: 400px;
}
.sideNav{
  height: 100vh;
  background: #EEF6FB;
  margin-left: -15px;
  position: relative;
  max-width: 400px;
}
.sideNav .navbar{
  padding: 0px!important;
}
.sideNav .navbar-nav{
  display: block;
  width: 100%;
}
.sideNav .navbar-nav a.nav-link{
  padding: 12px 20px;
  border-bottom: 1px solid #DADADA;
  text-transform: uppercase;
  color:#010167!important;
  font-size: 14px;
}
.sideNav .navbar-nav a.nav-link.active,
.sideNav .navbar-nav a.nav-link:hover{
  background: #fff;
  text-decoration: none;
  color:#010167!important;
}
.sideNav .navbar-nav a.nav-link img{
  margin:0 10px;
}

.dashHeader {background: #010167; height: 140px;}
.dashHeader .navbar {
  margin-top: 36px;
}
.dashHeader .logo{
  top:-21px;
}

@media (max-width:992px){
  .sideNav{
    display: none;
  }
  .dashHeader {
    height: 90px;
}
.dashHeader .navbar-collapse {
  background: #010167;
}
.dashHeader .navbar {
  margin-top: 0px;
  z-index: 99;
}
.dashHeader .navbar-toggler {
  margin-left: 22px;
  margin-top: 18px;
}
.dashHeader .logo{
  top:10px;
}
.navbar-light .navbar-toggler {

  background: #fff;
  border-radius: 0;
  border: 0px;
}
}


.stayupdate-section .info-block{
  position: relative;
}

.stayupdate-section .popup{
  position: absolute;
  top:25px;
  left: 0px;
  width: 130%;
  height: 275px;
  overflow: auto;
}
.stayupdate-section .popup::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}

.stayupdate-section .popup::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.stayupdate-section .popup::-webkit-scrollbar-thumb
{
	background-color:#010167;

}

@media (max-width:992px){
.stayupdate-section .popup{
  width: 100%;
  top:0px;
}
}


.bannerSecton.home-banner h2{
  color:#fff;
  border:0px solid #000;
  background: rgba(0, 173, 239, .6);
  border-radius: 4px;
  max-width: 970px;
  padding: 5px;
  margin: 0px auto;
  margin-bottom: 20px;
  border-radius:12px;
}

.schloarships-section .schloarship-list .scholarship-card.card .btn.btn-success{
  border-color: #007849;
  background-color: #007849; /*#0747A6*/;
  color:#fff;
  width:186px;
  padding:3px 20px;
}

@media (max-width:767px){
.schloarships-section .schloarship-list .scholarship-card.card .btn.btn-success{

  width:49%;
}
.ReactPasswordStrength-strength-desc{
width:237px !important;		
}
.forgetCls{
	margin-bottom:29px;
}
#info-section{
  padding: 15px!important;
}
.personal-info-section form {
  padding: 12px;
  box-shadow: none!important;
}
.schloarships-section .schloarship-list .scholarship-card.card .price-value .price {
  height: 44px;
}
.schloarships-section .schloarship-list {
   padding: 0px;
   box-shadow:none;
}
.bannerSecton.home-banner h2 {margin-top:160px}
}


.schloarships-section .schloarship-list .scholarship-card.card {
  min-height: 380px;
}
.schloarships-section .schloarship-list .scholarship-card.card a{
  color:#010167;
}

.schloarships-section .schloarship-list .scholarship-card.card .btn.btn-success:hover{
  border-color: #000; /*#ED8A2F; #070767;*/
  background: #000;
  color:#fff;
}

.schloarships-section .schloarship-list .scholarship-card.card .card-footer a{
  margin-top: 0px;
}
.groupReg-Form h3{
  color: #fff;
  margin-top: 40px;
}
.groupReg-Form label{
  color:#fff;
}
.groupReg-Form .form-control{
  border:3px solid #fff;
  background: none;
  border-radius: 0px;
  padding: 15px;
  height: 45px;
  color: #ffffff;
}

.schGridHead {

    padding: 6px;
    background: #E6E6E6;
    margin: 10px 0px;

}
.schGridCol{
	padding:10px;
}


/*Start: 04 June */

.logo:hover{
  transform: scale(1.05);
}
.home-nav.navbar-nav .nav-link{
  /*color:rgba(7, 7, 103, 1)!important;*/
}

.home-nav + .nav-icon .line{
  background: #010167;
}


.create-account-section .btn.btn-default { 
  border:3px solid #010167;
  color: #010167;
  background: none;
 
}
.create-account-section .btn.btn-default:hover{
  background: #010167;
  color:#fff;
}
.bannerSecton.main-banner .info-widget .btn.btn-default:hover{
  background: #fff;
  color:#010167;
  border-color: #010167;
}
#amountForm .btn-secondary,
#amountForm .btn-primary,
.schGridCol .card-link,
.schGridCol .btn.btn-success{
	border:3px solid #00ADEF;
	color: #fff;
	background-color: #00ADEF; /*#00ADEF*/
	border-radius: 0px;
	font-family: 'Fira Sans', sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	padding: 6px 20px;
	text-decoration: none;
}
#amountForm input{
	border-radius:0px;
	border: 1px solid #E1E1E1;
    box-shadow: 0px 5px 3px rgba(0, 0, 0, .1);
    /*height: 45px;*/
    font-family: 'Fira Sans', sans-serif;
	
}
#amountForm .btn-secondary:hover,
#amountForm .btn-primary:hover,
.schGridCol .card-link:hover,
.schGridCol .btn.btn-success:hover{
  border:3px solid #000; /*#ED8A2F;*/
  background: #000; /*#010167;*/
  color:#fff;
}

.heading-group .btn.btn-default{
	background: #010167;
	border: 3px solid #010167;
	text-transform: uppercase;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 20px;
	border-radius: 50px;
	padding: 16px 70px;
	color: #fff;
}

.heading-group .btn.btn-default:hover{
 border:3px solid #010167;
 background: #fff;
 color:#010167;
}

.applyCls{
	width:100%;
	text-align:center;
	box-shadow:none;
}

.btn-success.disabled, .btn-success:focus{box-shadow:none;}
.btn-success.disabled, .btn-success:disabled{
	background:#A7AAAF !important;
	border-color:#A7AAAF !important;
	color:#5A6569 !important;
	    box-shadow:none !important;
}
.btn-success.disabled, .btn-success:disabled:hover{
border-color:#A7AAAF !important;
box-shadow:none;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus{
	background:#A7AAAF !important;
		color:#5A6569 !important;
	    box-shadow:none !important;
		border-color: #A7AAAF !important;
}

/*End: 04 June */


.topnav a:before {
  background: #00ADEF;

}
.login-nav{
  margin: 0px auto;
  width: 100%;
  text-align: center;
  margin-left: -185px;
}

/*.schloarships-section .schloarship-list .scholarship-card.card .btn.btn-success {
  width: 150px;
}

@media (max-width:767px){
  .schloarships-section .schloarship-list .scholarship-card.card .btn.btn-success {
    width: auto;
  }
}*/

section.pricing {
  background:#010167;
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  margin: 15px;
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: .1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
  color:#000;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  background: #010167;
    border: 3px solid #010167;
    text-transform: uppercase;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px;
    border-radius: 30px;
    padding: 10px 25px;
    color: #fff;
    max-width: 200px;
    margin: 0px auto;
}
.pricing .btn:hover{
  background: none;
  border: 3px solid #010167;
  color:#010167;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }
  .pricing .card:hover .btn {
    opacity: 1;
  }
}


/*Start: 11 June 2019 for Popup*/
.service-box .box h3{
  text-transform: uppercase;
}
.getting-started{
  position: relative;
}
.service-box .box .btn-primary{
  border-radius: 25px;
  border:2px solid #fff;
  color:#fff;
  padding: 6px 20px;
  text-transform: uppercase;
  background: none;
  
}
.service-box .box .btn-primary:hover{
  background: #fff;
  color:#010101;
}
.service-box .box .info{
  position: absolute;
  top:0px;
  left: 3px;
  width: 100%;
  max-height: 220px;
  min-height:100%;
  height: 100%;
  overflow: auto;
  background:#2F2F82;
  color: #fff; 
  padding: 0px 12px;
}
.service-box .box .info h3{
  color:#fff;
  font-size:18px;
}
.service-box .box .info .close{
  position: absolute;
  right:20px;
  top:20px;
  font-size:12px;
  padding: 5px 8px 7px;
}
.bannerSecton.main-banner .info-widget.home-info h2{
  margin-bottom: 60px;

}
.bannerSecton.main-banner .info-widget.home-info .btn.btn-default{
  font-size: 20px;
  border-radius: 50px;
}
@media (max-width: 992px) {
  .service-box .box .info{
    min-height: 300px;
    max-height: 100%;
    height: 300px;
    z-index: 99;
    left: 0px;

  }
}

/*End: 11 June 2019 for Popup*/


/*Start: 27 June For Tooltip */
.btn.info-btn{
  padding:0px;
  background: #010167;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 0px;
  border: 0px;
  line-height: 0px;
  font-size: 12px;
  font-weight: 600;
  top: -2px;
  position: relative;
}
/*End: 27 June For Tooltip */



/*Start: 22 july For Modal */
.modal-box{
  padding: 60px;
  text-align: center;
  background: #010167;
  color:#fff;
  margin-top: 40px;
}
.modal-box h2{
  color:#fff;
  margin-bottom: 40px;
}
.styles_modal__gNwvD{
  background: none;
  box-shadow: none;
}
.styles_closeButton__20ID4 svg{
  fill:#fff;
}
.modal-box .btn-default {
  background: #010167;
  color: #fff;
  border-color: #010167;
  font-size:14px;
  border:2px solid #fff;
  text-transform: uppercase;
  border-radius: 50px;
  padding: 10px 30px;
}
.modal-box .btn-default:hover{
  background: #fff;
  color: #010167;
}
/*End: 22 july For Modal */



/*Start 26 July for Select Modal*/

.styles_modal__gNwvD{
  background: none;
  box-shadow: none;
}
.modal-box.select-box{
  padding: 0px;
  min-width: 450px;
  text-align: left;
}
.modal-box.select-box h2{
  font-size: 22px;
  text-align: left;
  background: #010167;
  color:#fff;
  padding:20px 30px;
  margin-bottom: 0px;
}
.modal-box.select-box .popup-body{
  padding: 30px;
  background: #fff;
}
.modal-box.select-box .popup-body .btn-default{
  background: none;
  color:#010167;
  border:2px solid #010167;
}
.modal-box.select-box .popup-body .btn-default:hover{
  background: #010167;
  color:#fff;
}
/*End 26 July for Select Modal*/



/*Start: 30July2019*/
.modal-box.select-box .popup-body .custom-checkbox,
.race-panel .custom-checkbox{
  cursor: pointer;

}
.race-panel .checklist{
  height: 75px;
  overflow: auto;
  display: block;
  position: relative;
  margin-bottom: -18px;
}
.modal-box.select-box .popup-body label,
.race-panel label{
  cursor: pointer;
}

.pricing .card.Freewidget:hover{
  margin-top: 15px;
}
.pricing .card.Freewidget:hover .btn.disabled{
  opacity: 0.65;
}

@media (max-width: 767px) {
  .race-panel .checklist{
    margin-bottom: 0px;
    height: auto;
  }
}

/*End: 30July2019*/

/*Start: 13Aug 2019*/
.modal-box.select-box .popup-body { width:500px; padding: 30px; background: #fff; height: 400px; overflow: auto;}
/*End: 13Aug 2109*/



/*Start : Count Down*/
.count-wrap{
  font-size: 35px;
  font-weight: bold;
  margin:0px auto;
  margin-top:247px;
  border-radius: 60px;
  text-shadow: 1px 1px 1px #010167;
  font-style: italic;
}
.count-wrap span{
  display: block;
  width: 100%;
  text-align: center;
}
/*End : Count Down*/




/*Start 02 Sep*/
.group-registration-banner{
  min-height: 500px;
  background-position:0 -160px!important;
  margin-bottom: 60px;
}
.count-sec{
  color:#fff;
  text-align: center;
  z-index: 999;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;

}
.education-section .info {
  color: #fff;
  max-width: 730px;
   position: relative;
  margin: 0px auto;
  margin-top: 200px;
  text-align: center;
  z-index: 99;
}
.footer-banner{
  min-height: 300px;
  position: relative;
}
@media (max-width: 1024px) {
.group-registration-banner{
  background-position:0 0px!important;
  margin-bottom: 20px;
  min-height: 300px;
  margin-left: 400px;
}
}
@media (max-width: 1024px) {
  .count-sec{
   margin-top:40px;
   line-height: 30px;
   font-size: 24px;
   padding:0 15px;
   text-align: center;
}
.footer-banner{
  display: none;
}
.education-section .info {

  margin: 50px 0;

}
}

/*End 02 Sep*/


/*Start 30Sep */
footer{ 
  padding: 120px 0 100px!important; 
}
footer h4{
  margin-bottom: 20px;
}
footer ul.social-widget {
  text-align: center;
  margin-top: 0px; 
}
footer ul.social-widget li{
  width: 40px;
  height: 40px;
}
/*End 30Sep */


/*Start 05 Nov */
@media (max-width: 1380px) {

.schloarships-section .schloarship-list .scholarship-card.card .btn.btn-success{
  width:auto;
}
}

/*End 05 Nov */


/*Start 15 Nov */
.pricing.dashboard-pricing .card{
  background:rgba(0, 173, 239, .4);
   
    margin:15px 0px;
}
.pricing.dashboard-pricing .card.active{
  background:rgba(0, 173, 239, .2);
  box-shadow: none;
}
.pricing.dashboard-pricing .card.active .btn{
  background: none;
  border-radius: 0;
  border:0px;
  padding: 24px 0px 0px;
  max-width:100px;
  color:#010167;
  text-decoration: underline;
  font-weight: 700;
}
.pricing.dashboard-pricing .card.active .btn:hover{
  text-decoration: none;
}
@media (max-width: 767px){
  .schloarships-section .schloarship-list {
      padding:20px 0px;

  }
} 
/*End 15 Nov */



/*Start 19 Nov */
.video-modal.subscribe-model .modal-content{
  max-width: 450px;
  margin:0px auto;
}

.video-modal.subscribe-model .modal-body{
  background: #fff;
  padding: 40px;
  padding-bottom: 46px;
  text-align: center;
}
.video-modal.subscribe-model .modal-body h3{
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
.video-modal.subscribe-model .modal-body p{
  font-size: 13px;
  margin-bottom: 10px;
}
.video-modal.subscribe-model .btn,
.video-modal.subscribe-model .form-control{
  font-size: 12px;
  padding: 0px 15px;
  height: 40px;
}
.video-modal.subscribe-model .form-control{
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.video-modal.subscribe-model .btn{
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.video-modal.subscribe-model .btn.btn-primary{
  background: #010167;
  border-color: #010167;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}
.video-modal.subscribe-model .btn.btn-primary:hover{
  background: #000000;
  border-color: #000;
}
.alert-warning.warning-info{
    color: #ff6600;
    background-color: #ffe1cd;
    border-color: #ffe1cd;
}
/*End 19 Nov */

/*Start 21 Nov */
.video-modal.subscribe-model .btn_cancel_plan{  
	background: #010167;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    border-radius: 25px;
    padding: 10px 20px;
    color: #fff;
    max-width: 200px;
    margin: 0px auto;
	text-decoration:none;
}

.video-modal.subscribe-model .btn_cancel_plan:hover{
  background: #000000;
  border-color: #000;
}
/*End 21 Nov */




/*Start 22 Nov */
.panel-collapse {
  max-height: 500px;
  overflow: hidden;
  transition: max-height .5s ease;
}
.panel-close {
  max-height: 0;
}
/*End 22 Nov */

/*start 5 Dec */
.subscribe-widget{
  max-width: 500px;
  margin: 0px auto;
}
.subscribe-widget .form-control{
  border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    padding: 26px;
    border:0px;
}
.subscribe-widget .btn.btn-primary{
  background: #010167;
  border:1px solid #010167
}
.susbcribe-info{
    font-size: 20px;
    max-width: 500px;
    margin: 15px auto;
    line-height: 22px;
    font-weight: 500;
    text-shadow: 2px 1px 1px #000;
}
/*start 5 Dec */

.removeIcon{position: absolute;
			top: 10px;
			right: 15px;
		   }
		   
.removeIcon a{ color: #000000 !important; }


.filter-sidebar .rangeslider-horizontal .rangeslider__fill,
.filter-sidebar .rangeslider .rangeslider__handle {
  background-color: #FFB81C;
}
.schloarships-section .schloarship-list .scholarship-card.card .price-value .price {
  border: 3px solid #FFB81C;
}

.create-account-section .btn.btn-default {
  border: 3px solid #FFB81C;
  color: #FFB81C;
  background: none;
}
.create-account-section .btn.btn-default:hover {
  border: 3px solid #FFB81C;
  color: #fff;
  background: #FFB81C;
}
header{
  background: linear-gradient(180deg, #154734 3.38%, rgba(51, 80, 70, 0.833333) 52.04%, rgba(255, 255, 255, 0) 100%);
}
.nav-link {
  display: inline!important;
  display: initial!important;
}
.schloarships-section .schloarship-list .scholarship-card.card .card-title {
  color:#000
}
.schloarships-section{
  margin-top: 85px;
  margin-bottom: -44px;
}
.schloarships-section .schloarship-list {
  padding: 40px;
  box-shadow: -3px 0px 5px -3px rgba(0, 0, 0, 0.25);
}
#amountForm .btn-secondary, #amountForm .btn-primary, .schGridCol .card-link, .schGridCol .btn.btn-success {
  border: 3px solid #007849;
  color: #fff;
  background-color: #007849;
}
.removeIcon {
  top: 12px;
  right: 2px;
}
.schloarships-section .schloarship-list .scholarship-card.card .card-body .removeIcon {
  top: 8px;
  right: 10px;
}

.sideNav .navbar-nav a.nav-link {
  display: block!important;
  color: #007849!important;
}


.sideNav .navbar-nav a.nav-link.active, .sideNav .navbar-nav a.nav-link:hover {
  color: #007849!important;
}
.sideNav{
  background: rgba(0,120,73,.1);
}

.schloarship-list .btn,
.schloarship-list .btn.btn-default{
  border:1px solid #007849!important; 
  color: #007849!important;
}
.schloarship-list .btn:hover,
.schloarship-list .btn.btn-default:hover{
  background: #007849!important;
  color: #fff!important;
}

.schloarship-list .btn-primary {
  color: #fff!important;
  background-color: #007849;
  border-color: #007849;
}
.schloarship-list .btn-primary:hover{
  color: #007849!important;
  background-color:#fff!important;
}

.pricing.dashboard-pricing .card {
  background: rgba(0, 120, 73, .2);
  margin: 15px 0px;
}
.pricing.dashboard-pricing .card  ul{
  margin-left: 30px;
}

.btn.btn-secondary {
  background: #007849;
  color:#fff!important;
  padding: 0!important;
  height: 20px!important;
}

.schGridCol .btn.btn-success,
.schloarships-section .schloarship-list .scholarship-card.card .btn.btn-success {
  color: #fff!important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.schGridCol .btn.btn-success:hover,
.schloarships-section .schloarship-list .scholarship-card.card .btn.btn-success:hover{
  border: 0px solid #000!important;
    background: #000!important;
    color: #fff;
} 
.modal-box, 
.bannerSecton {
  background: #154734;
}

.bannerSecton.main-banner .info-widget .btn.btn-default {
  background: #154734;
  color: #fff;
  border-color: #154734;
}
.modal-box .btn-default{
  background: #154734;
}
.bannerSecton.main-banner .info-widget {
  margin-top: 240px;
}
.highlightClass{
	background-color:#007849;
}

body,
html,
header,
footer,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
section,
main{
	margin:0px;
	padding:0px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6{
  font-weight: normal
}
.sepration{
  margin-top: 40px;
}
.bg-green-pea{
  background: #154734;
}
h2{
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 700;
}
h4{
  font-size: 22px;
  font-weight: 700;
  
}
.btn{
  border-radius: 25px!important;
  padding: 6px 25px!important;
  text-transform: uppercase;
  height: 40px!important;

}

/*Start: Header*/

header{
  position: fixed;
  top:0px;
  left: 0px;
  width: 100%;
  z-index: 999;
  transition: all .4s;
}
header.normal{
  background: #154734;
}
header .search-section{
  min-width: 164px;
}
.navbar-nav,
.navbar{
  width: 100%;
}
header .navbar-light .navbar-nav .nav-link {
  color:#fff;
  font-size: 15px;
  font-weight: 400;
  padding: 0px;
  margin: 0px 15px;
  border-bottom:2px solid transparent;
  transition: all .4s;
}
header .navbar-light .navbar-nav{
  justify-content: center;
}
header .navbar-light .navbar-nav .nav-link:hover{
  border-bottom:2px solid #fff;
  color:#fff;
  text-decoration: none;
}

header .search-section .navbar-nav{
  justify-content: flex-end;
}
header .search-section .navbar-nav .nav-link:hover{
  border:0px;
  opacity: .5;
}

header .search-section .navbar-nav {
  justify-content: flex-end;
  display: flex;
  flex-direction: inherit;
}
#searchFilter{
  width:280px;
  top:62px;
  position: fixed;
  padding:15px;
  z-index: 999;
  right: 35px;
  background: #fff;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, .3);
  border-radius: 0px;
}
#searchFilter .form-control{
  background: url(/assets/img/searchGreen.svg) no-repeat center right;
  background-position: 95% 12px;
  border-radius: 25px;
}
#SidebarBox{
  position: fixed;
  width: 300px;
  height: 100vh;
  background: #fff;
  right: 0px;
  top: 0px;
  z-index:999;
  box-shadow: -8px 0px 8px rgba(0, 0, 0, .3);
}
#SidebarBox .close{
  position: absolute;
  background: #ffb91d;
  color:#fff;
  right: 15px;
  top:18px;
  font-weight: 400;
  padding: 8px 10px;
  font-size: 14px;
  opacity: 1;
  transition: all .4s;
  border-radius: 25px;
}
#SidebarBox .close:hover{
  background: #154734;
}
#SidebarBox .head{
  padding:15px 15px 12px;
  background: #154734;
}
#SidebarBox .head h4{
  text-transform: uppercase;
  font-size: 22px;
  margin-top: 2px;
}

/*End: Header*/


/*Start: Hero Banner*/
.banner-section{
  height: 615px;
  overflow: hidden;
  position: relative;
}

.banner-section::before{
  position: absolute;
  height: 100%;
  left: 0;
  bottom: 0;
  content: '';
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 61.69%, rgba(78, 103, 94, 0.723958) 82.64%, #154734 100%), linear-gradient(184.15deg, #154734 3.38%, rgba(51, 80, 70, 0.833333) 15.04%, rgba(255, 255, 255, 0) 52.05%);
  width: 100%;

}
.banner-section .caption{
  position: absolute;
  width: 100%;
  z-index: 99;
  height: 100%;
  color:#000;
  display: flex;
  align-items: flex-end;
  text-align: center;
  padding-bottom: 45px;
}
.banner-section .caption h4,
.banner-section .caption h2{
  color:#fff;
}

.banner-section .caption h2{
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 700;
}
.banner-section .caption h4{
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 15px;
}
.banner-section .btn{
  border-radius: 25px;
  padding: 6px 25px;
  text-transform: uppercase;
  height: 40px;

}
.banner-section .btn.btn-warning{
  border:2px solid #fff;
  color:#fff;
  font-size: 16px;
  font-weight: 700;
}
/*End: Hero Banner*/


.undergraduate-section .imgblock,
.undergraduate-section{
  min-height: 388px;
}

.undergraduate-section .infoBlock{
  padding: 40px;
}
.undergraduate-section .infoBlock .btn.btn-outline{
  border:2px solid #ffb91d;
  color:#ffb91d;
  background: none;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.undergraduate-section .infoBlock .btn.btn-outline:hover{
  background: #ffb91d;
  color:#fff;
}
.undergraduate-section .infoBlock li{
  list-style: none;
}
.undergraduate-section .infoBlock li a{
  font-size: 14px;
  font-weight: 300;
  color:#fff;
  text-decoration: underline;
}
.undergraduate-section .infoBlock li a:hover{
  color: #ffb91d;
}

/*Start: Scholarship*/
.bg-green-pea{
	background:#164734;
}
.scholarship-section .scholarship-section-wrap{
	min-height:425px;
}
.scholarship-section .scholarship-section-wrap h2{
	text-transform:uppercase;
	text-align:center;
	padding:30px 0;
  font-weight: bold;
  color:#fff;
}
.scholarship-section .scholarship-section-wrap h2:after {
    content: '';
    display: block;
  	width: 230px;
    height: 2px;
    background: #fff;
    margin: 0px auto;
    margin-top: 10px;
}
.scholarship-section .scholarship-section-img{
  max-width: 1080px;
  margin: 0px auto;
  margin-top:-90px;
  
}
/*End: Scholarship*/



/*Start: About*/
.about-section .about-section-wrap .btn-default{
	color:#ffb91d;
	border:1px solid #ffb91d;
	border-radius:970px;
	font-weight: 500;
	margin-top:20px;
	transition: 1.2s;
}
.about-section .about-section-wrap .btn-default:hover{
	background:#ffb91d;
	color:#fff;
	transition: 1.2s;
}
/*End: About*/


/*Start: Footer*/
footer .main-footer{
	background:#212121;
}
footer .main-footer p{
  color:#fff;
  font-size: 14px;
}
footer .main-footer h5{
	font-size:15px;
	font-weight: 700;
  margin-bottom:30px;
  color: #fff;
}
footer .main-footer a{
	color:#fff;
  transition: .5s;
  font-size: 15px;
}
footer .main-footer a:hover{
	color:#ffb91d;
	text-decoration:none;
	transition: .5s;
}
footer .main-footer li{
  list-style: none;
  font-size: 15px;
}
footer .termCondition-Widget{
  margin-top: 90px;
}
footer .bootom-footer{
	background:#000;
  font-size:12px;
  font-weight: 700;
  color:#fff;
}
footer .bootom-footer a{
	color:#fff;
	transition: .5s;
}
footer .bootom-footer a:hover{
	color:#ffb91d;
	text-decoration:none;
	transition: .5s;
}
footer .bootom-footer a.site-map{
	color:#ffb91d;
	text-decoration:underline;
	transition: .5s;
}
footer .bootom-footer a.site-map:hover{
	text-decoration:none;
	transition: .5s;
}
/*End Footer*/



@media (max-width:1300px){
  header .navbar-light .navbar-nav .nav-link{
    margin: 0px 10px;
  }
 
}
@media (max-width:1200px){
  header{
    background: #154734;
  }
  header .search-section{
    min-width: auto;
    position: absolute;
    top:18px;
    right: 15px;
  }
  .navbar-light .navbar-toggler{
    margin-right: 85px;
    border: 0px;  
  }
  header .navbar-light .navbar-nav .nav-link{
    padding: 6px 0;
  }
  header .search-section .navbar-nav .nav-link{
    padding: 0px 0;
  }
}
@media (max-width:992px){
.scholarship-section .scholarship-section-img {
  margin-top: -30px;
}
}
@media (max-width:767px){
  .banner-section {
    height: 500px;
    overflow: hidden;
    position: relative;
}
.scholarship-section .scholarship-section-wrap{
  padding-bottom: 40px;
}
	.scholarship-section .scholarship-section-img{
		margin-top:0;
	}
	footer .main-footer, footer .bootom-footer, footer .bootom-footer ul{
		text-align:center!important;
	}
	footer .main-footer h5{
		margin:25px 0 10px;
  }
  footer .bootom-footer .list-inline{
    margin-top: 15px;
  }
  footer .termCondition-Widget{
    margin-top: 0px;
  }
}
.navbar-brand {margin-left:15px;}
footer {
  padding: 0!important; 
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.alink {font-size: 12px;}
.comingsoon {height: 400px; text-align: center;font-size: 28px;padding-top: 20px}

#b1{
    color: #164734;
}

#b1:hover{
    color:white;
    background-color: black;
}
#b1:focus{
    color:white;
    background-color: black;
}



#b1:hover{
    color:white;
    background-color: black;
}
#b1{
    color: #164734;
}

#b2:hover{
    color:white;
    background-color: black;
}
#b2{
    color: #164734;
}
#loginSubmit:hover{
    color:white;
    background-color: black;
}
#loginSubmit{
    color: #164734;
}
#regSubmit:hover{
    color:white;
    background-color: black;
}
#regSubmit{
    color: #164734;
}
#applybtn:focus{
    color:white;
    background-color: black;
}
.custom-btn:focus {
    background-color: black !important;
    border: white !important;
  }
  #datepicker{
      display: none;
  }
  #cardTitle:hover{
    color:#007849;
}

#applybtn:focus{
    color:white;
    background-color: black;
}
.custom-btn:focus {
    background-color: black !important;
    border: white !important;
  }
  #cardTitle:hover{
    color:#007849;
}

#applybtn:focus{
    color:white;
    background-color: black;
}
.custom-btn:focus {
    background-color: black !important;
    border: white !important;
  }
  #cardTitle:hover{
      color:#007849;
  }

#applybtn:focus{
    color:white;
    background-color: black;
}
#applybtn1:focus{
    color:white;
    background-color: black;
}
.custom-btn:focus {
    background-color: black !important;
    border: white !important;
  }
  #cardTitle:hover{
    color:#007849;
}

